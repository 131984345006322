import React from 'react';
import Logo from './pgvector.svg';

const DemoBanner = () => {
  return (
    <div
      className="nsw-p-y-xs"
      style={{ backgroundColor: 'var(--nsw-grey-04)', paddingInline: '2rem' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <a
          style={{ backgroundColor: 'transparent' }}
          href="https://pretagov.com.au"
        >
          <img height="40px" src={Logo} alt="PretaGov" />
        </a>
        {/* <span style={{ display: 'inline-flex', alignItems: 'flex-end' }}> */}
        <h2
          className="nsw-h3"
          style={{
            display: 'inline',
            marginInline: '20px',
            marginBlockStart: '1rem',
          }}
        >
          Digital NSW Plone Starter Kit Demo
        </h2>
        <a
          href="https://github.com/pretagov/nsw-design-system-plone6-kit"
          className="nsw-button nsw-button--dark-outline-solid"
        >
          <span>View on GitHub</span>
          <span
            className="material-icons nsw-material-icons"
            focusable="false"
            aria-hidden="true"
          >
            open_in_new
          </span>
        </a>
        {/* </span> */}
      </div>
    </div>
  );
};
export default DemoBanner;
