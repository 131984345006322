export function separatorSchema() {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [],
        required: [],
      },
    ],
    required: [],
    properties: {},
  };
}
